import React,{ useEffect,useState } from 'react'
import { useLocation } from "@reach/router"

import SEOMetaData from '../../components/background/SEOMetaData.js';
import {InterestTracker} from "../../components/background/InterestTracker.js";
import GoogleAnalyticsEvent from "../../components/background/GoogleAnalyticsEvent.js";
import { OutboundLink } from "gatsby-plugin-google-gtag"

import Tier3Layout from "../../components/content/Tier3Layout"
import ArticleSidebar from "../../components/content/ArticleSidebar"
import ConsultationRequestTrigger from "../../components/forms/ConsultationRequestTrigger"
import SampleItineraries from "../../components/content/SampleItineraries"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Carousel from 'react-bootstrap/Carousel'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { faChevronDoubleRight } from '@fortawesome/pro-solid-svg-icons'
import { faChevronDoubleLeft } from '@fortawesome/pro-solid-svg-icons'

import '../../styles/tier3.css'
import '../../styles/spiritual.css'
import itinerary_maya_hotel_1 from '../../images/sampleitinerary_carousels/spiritual_restoration/spiritual-maya-hotel-1.jpg'; 
import itinerary_maya_hotel_2 from '../../images/sampleitinerary_carousels/spiritual_restoration/spiritual-maya-hotel-2.jpg'; 
import itinerary_maya_hotel_3 from '../../images/sampleitinerary_carousels/spiritual_restoration/spiritual-maya-hotel-3.jpg'; 
import itinerary_maya_hotel_4 from '../../images/sampleitinerary_carousels/spiritual_restoration/spiritual-maya-hotel-4.jpg'; 
import itinerary_maya_activity_1 from '../../images/sampleitinerary_carousels/spiritual_restoration/spiritual-maya-activity-1.jpg'; 
import itinerary_maya_activity_2 from '../../images/sampleitinerary_carousels/spiritual_restoration/spiritual-maya-activity-2.jpg'; 
import itinerary_maya_activity_3 from '../../images/sampleitinerary_carousels/spiritual_restoration/spiritual-maya-activity-3.jpg'; 
import itinerary_maya_activity_4 from '../../images/sampleitinerary_carousels/spiritual_restoration/spiritual-maya-activity-4.jpg'; 
import itinerary_maya_activity_5 from '../../images/sampleitinerary_carousels/spiritual_restoration/spiritual-maya-activity-5.jpg'; 
import itinerary_christian_paul_1 from '../../images/sampleitinerary_carousels/spiritual_restoration/spiritual-christian-paul-1.jpg'; 
import itinerary_christian_paul_2 from '../../images/sampleitinerary_carousels/spiritual_restoration/spiritual-christian-paul-2.jpg'; 
import itinerary_christian_paul_3 from '../../images/sampleitinerary_carousels/spiritual_restoration/spiritual-christian-paul-3.jpg'; 
import itinerary_christian_paul_4 from '../../images/sampleitinerary_carousels/spiritual_restoration/spiritual-christian-paul-4.jpg'; 
import itinerary_christian_shrines_1 from '../../images/sampleitinerary_carousels/spiritual_restoration/spiritual-christian-shrines-1.jpg'; 
import itinerary_christian_shrines_2 from '../../images/sampleitinerary_carousels/spiritual_restoration/spiritual-christian-shrines-2.jpg'; 
import itinerary_christian_shrines_3 from '../../images/sampleitinerary_carousels/spiritual_restoration/spiritual-christian-shrines-3.jpg'; 
import itinerary_christian_shrines_4 from '../../images/sampleitinerary_carousels/spiritual_restoration/spiritual-christian-shrines-4.jpg'; 
import itinerary_christian_shrines_5 from '../../images/sampleitinerary_carousels/spiritual_restoration/spiritual-christian-shrines-5.jpg'; 
import itinerary_india_places_1 from '../../images/sampleitinerary_carousels/spiritual_restoration/spiritual-india-places-1.jpg'; 
import itinerary_india_places_2 from '../../images/sampleitinerary_carousels/spiritual_restoration/spiritual-india-places-2.jpg'; 
import itinerary_india_places_3 from '../../images/sampleitinerary_carousels/spiritual_restoration/spiritual-india-places-3.jpg'; 
import itinerary_india_places_4 from '../../images/sampleitinerary_carousels/spiritual_restoration/spiritual-india-places-4.jpg'; 
import itinerary_india_places_5 from '../../images/sampleitinerary_carousels/spiritual_restoration/spiritual-india-places-5.jpg'; 
import itinerary_india_places_6 from '../../images/sampleitinerary_carousels/spiritual_restoration/spiritual-india-places-6.jpg'; 
import itinerary_india_journey_1 from '../../images/sampleitinerary_carousels/spiritual_restoration/spiritual-india-journey-1.jpg'; 
import itinerary_india_journey_2 from '../../images/sampleitinerary_carousels/spiritual_restoration/spiritual-india-journey-2.jpg'; 
import itinerary_india_journey_3 from '../../images/sampleitinerary_carousels/spiritual_restoration/spiritual-india-journey-3.jpg'; 
import itinerary_india_journey_4 from '../../images/sampleitinerary_carousels/spiritual_restoration/spiritual-india-journey-4.jpg'; 
import itinerary_india_people_1 from '../../images/sampleitinerary_carousels/spiritual_restoration/spiritual-india-people-1.jpg'; 
import itinerary_india_people_2 from '../../images/sampleitinerary_carousels/spiritual_restoration/spiritual-india-people-2.jpg'; 
import itinerary_india_people_3 from '../../images/sampleitinerary_carousels/spiritual_restoration/spiritual-india-people-3.jpg'; 
import itinerary_india_people_4 from '../../images/sampleitinerary_carousels/spiritual_restoration/spiritual-india-people-4.jpg'; 

import headerimage from '../../images/headerimage-spiritualrestoration.jpg'

const SpiritualRestoration = () => {
    const pageData = {
                        headline:{
                            text:<><span class="hidden-xs hidden-sm hidden-md">Vacations for </span>Spiritual Restoration</>,
                            image:headerimage
                        },
                        sectionnav:{section:'vacations'},
                        seo:{
                            title:'Vacations for Spiritual Restoration & Wellness',
                            description:'Slogging through life? It\'s time for a break! A vacation is an opportunity to step back from the daily grind, clear your head, recommit to your passion, or find creative inspiration',
                            image:'/images/socialmedia/socialmediaimage_spiritual.jpg'
                        }
                    }; 


    let location = useLocation(); 
    useEffect(() => {
        InterestTracker('Spiritual Restoration',location); 
    },[location]);

    const { search } = useLocation()
    const [showConsultationForm,setShowConsultationForm] = useState(false); 

    const [mayaCarousel1Index, setMayaCarousel1Index] = useState(0);
    const [mayaCarousel2Index, setMayaCarousel2Index] = useState(0);
    const [christianCarousel1Index, setChristianCarousel1Index] = useState(0);
    const [christianCarousel2Index, setChristianCarousel2Index] = useState(0);
    const [indiaCarousel1Index, setIndiaCarousel1Index] = useState(0);
    const [indiaCarousel2Index, setIndiaCarousel2Index] = useState(0);
    const [indiaCarousel3Index, setIndiaCarousel3Index] = useState(0);

    const handleCarouselSelect = (section,carousel,selectedIndex) => {
        GoogleAnalyticsEvent("sampleitinerary_carousel_interaction",{section:section,carousel:carousel})
        if(section==='maya'){ 
            if(carousel===1){ 
                setMayaCarousel1Index(selectedIndex); 
            } else if(carousel===2){ 
                setMayaCarousel2Index(selectedIndex); 
            }
        } else if(section==='christian'){ 
            if(carousel===1){ 
                setChristianCarousel1Index(selectedIndex); 
            } else if(carousel===2){ 
                setChristianCarousel2Index(selectedIndex); 
            }
        }else if(section==='india'){ 
            if(carousel===1){ 
                setIndiaCarousel1Index(selectedIndex); 
            } else if(carousel===2){ 
                setIndiaCarousel2Index(selectedIndex); 
            } else if(carousel===3){ 
                setIndiaCarousel3Index(selectedIndex); 
            }
        }
    };
    
    function startConsultationRequest(which){ 
        GoogleAnalyticsEvent("start_consultationrequest",{buttonLocation:which})
        setShowConsultationForm(true); 
    }
    
    function getStartingPane(){ 
        if(search!==""){ 
            var querystring = search.substring(1); 
            var querystringarray = querystring.split('&'); 
            var thisParam; 
            for(var i=0;i<querystringarray.length;i++){ 
                thisParam = querystringarray[i].split('='); 
                if(thisParam[0]==='view'){ 
                    return thisParam[1]; 
                    // return Itineraries[thisParam[1]]; 
                }
            }
        } else { 
            return ''; 
        }
    }

  return (
    <main>
        <SEOMetaData title={pageData.seo.title} description={pageData.seo.description} image={pageData.seo.image} />
        <Tier3Layout headline={pageData.headline} sectionnav={pageData.sectionnav} showConsultForm={showConsultationForm} onCloseConsultForm={()=>{setShowConsultationForm(false)}}>

            <article>
            <p>When you’re slogging through daily life, losing motivation at work, or drifting away from your goals and dreams, then it’s time for a break!  Regardless of your religious or spiritual beliefs, a vacation is an opportunity to step back from the daily grind, clear your head, recommit to your passion, or find creative inspiration. </p>

            <Row>
                <Col lg={8}>
                        <p>Wellness & spiritual restoration vacations should be guided by your passions and personal needs. Where do you picture yourself? </p>
                        <ul>
                            <li>In a beach-side villa, sipping well-made daiquiris and napping to the sounds of waves between dips in a crystal clear pool. </li>
                            <li>Finding zen at meditation and yoga retreats, led by insightful guides who help you recenter yourself through gentle movements and quiet reflection. </li>
                            <li>Hiking trails among ancient ruins, letting the physical exertion reinvigorate a tired mind and bored muscles while revelling in the mysteries of long-gone cultures.</li>
                            <li>Visiting ancient sites of your faith—or other faiths—to better understand the history and journey of those who came before you.  </li>
                        </ul>
                        <p>Outward Travel can help plan just the right mix of relaxation and activities that suit your personal journey. During our initial consultation, tell us what inspires you, what gives you joy, how you find peace, and we’ll help you plan a vacation that will leave you ready for the year ahead. </p>
                        <ConsultationRequestTrigger onClick={()=>{startConsultationRequest('main_content')}} />
                    </Col>
                    <Col lg={4}>
                        <ArticleSidebar id="VacationScience" headline="Use that PTO!">
                            <h4>Science Shows Vacations Improve Health & Productivity</h4>
                            <p>A four-night vacation reduces stress and improves well-being. <OutboundLink href="https://www.mdpi.com/1660-4601/15/1/130" target="research" title="Click to read the research"><FontAwesomeIcon icon={faExternalLinkAlt} size="sm" /></OutboundLink></p>
                            <p>A 7-10 day vacation helps manage stress and reduces burnout. <OutboundLink href="https://www.researchgate.net/publication/232484878_Annual_vacation_Duration_of_relief_from_job_stessors_and_burnout" target="research" title="Click to read the research"><FontAwesomeIcon icon={faExternalLinkAlt} size="sm" /></OutboundLink></p>
                            <p>Work-related stress packs less of a punch the closer you get to a vacation. <OutboundLink href="https://www.tandfonline.com/doi/abs/10.1080/08870446.2019.1687699" target="research" title="Click to read the research"><FontAwesomeIcon icon={faExternalLinkAlt} size="sm" /></OutboundLink></p>
                            <p>People who take annual vacations have lower risk of heart disease. <OutboundLink href="https://journals.lww.com/psychosomaticmedicine/Abstract/2000/09000/Are_Vacations_Good_for_Your_Health__The_9_Year.3.aspx" target="research" title="Click to read the research"><FontAwesomeIcon icon={faExternalLinkAlt} size="sm" /></OutboundLink></p>
                            <p>A relaxed state of mind in different environments improves creativity. <OutboundLink href="https://journals.aom.org/doi/abs/10.5465/amj.2012.0575" target="research" title="Click to read the research"><FontAwesomeIcon icon={faExternalLinkAlt} size="sm" /></OutboundLink></p>
                        </ArticleSidebar>
                        <div className="sidebar">
                        </div>
                    </Col>
            </Row> 
            </article>

            <SampleItineraries id="Spiritual_Agendas" 
                sectionHeadline={<>
                    <span className="hidden-xs">Ideas for a Wellness Vacation</span>
                    <span className="hidden-sm hidden-md hidden-lg hidden-xl hidden-xxl">Vacation Ideas</span>
                    </>}color="red" displayFirst={getStartingPane()} panes={[
                {
                    headline:"Riviera Maya Meditations",
                    linktext:"Riviera Maya Meditations",
                    key:"riviera-maya",
                    content:<>
                    <p>Stretching along Mexico’s Caribbean coastline is the beautiful Riviera Maya region. The natural beauty—with birdsong lofting through the fresh air and turquoise ocean lapping at the shore— is enough to soothe the weariest travelers. Fresh fruits rich in flavor, soft nighttime winds, and a wide range of activities from beachside yoga to exploring truly incredible natural and ancient sites, all contribute to a restorative and inspiring break from day to day life. </p>
                    <Carousel nextIcon={<FontAwesomeIcon icon={faChevronDoubleRight} size="2x" /> } 
                            prevIcon={<FontAwesomeIcon icon={faChevronDoubleLeft} size="2x" /> } 
                            onSelect={(selectedIndex,e)=>{handleCarouselSelect('maya',1,selectedIndex)}} activeIndex={mayaCarousel1Index} interval={null}>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_maya_hotel_1} alt="Swim-up to your hotel room" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_maya_hotel_2} alt="Relax with a drink in the tub" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_maya_hotel_3} alt="White sand beach steps from the hotel" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_maya_hotel_4} alt="Hotel bar at night" />
                        </Carousel.Item>
                    </Carousel>
                    <p>Your home for this journey is a stunning, all-inclusive resort.  On your first night, settle into your room and grab a drink from the minibar—which has been pre-stocked with your preferred libations and soft drinks (and are free!). Start each day with a dip in the dip in the pool that meets your back patio, or let your mind wander as you saunter along the white sand beach. Enjoy a pool-side lunch, and dinner at whichever on-site restaurant strikes your fancy. </p>
                    <Carousel nextIcon={<FontAwesomeIcon icon={faChevronDoubleRight} size="2x" /> } 
                            prevIcon={<FontAwesomeIcon icon={faChevronDoubleLeft} size="2x" /> } 
                            onSelect={(selectedIndex,e)=>{handleCarouselSelect('maya',2,selectedIndex)}} activeIndex={mayaCarousel2Index} interval={null}>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_maya_activity_1} alt="Spa at Hotel Unico" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_maya_activity_2} alt="Swimming in a cenote" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_maya_activity_3} alt="Yoga" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_maya_activity_4} alt="Shaman ritual" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_maya_activity_5} alt="Cave meditation" />
                        </Carousel.Item>
                    </Carousel>
                    <p>The beautiful setting is just the start of your wellness & spiritual restoration experience. Fill yourself with the good vibes of ancient harmony doing yoga in a natural setting. Drain away the toxins of daily life with a Mayan steam bath ritual guided by a local shaman. Marvel at the ancient ruins of Tulum or Uxmal led by a descendent of the people that built them. Float peacefully in an exclusive natural cenote—a below-ground natural pool—hidden in the nearby jungle. If you prefer to destress through exercise, swim with dolphins and whale sharks, or set sail across the bay to Isla Mujeres where you can SCUBA in an underwater museum of natural wonders and fallen statues. </p>
                    <p>Return home with a deeper sense of yourself, and a broader view of the world. Carry the wisdom of the ancient people who were here before. Breathe deep knowing that the stress of daily life has no strength against the peace found in the Riviera Maya. </p>
                <ConsultationRequestTrigger color="red" size="sm" 
                    text={<><span className="hidden-xs hidden-sm">Start Planning Your</span>
                             <span className="hidden-md hidden-lg hidden-xl hidden-xxl">Plan a</span> Riviera Maya Escape</>} 
                    onClick={()=>{startConsultationRequest('sample_riviera-maya')}} />
                    <p className="legalease">Hotel description based on UNICO 20°87°; pictures &copy; AIC Hotel Group. Described activities are a mix of local experiences. Speak with Outward Travel and we’ll get the latest offers available only via travel agents, and help you plan a relaxing and restorative vacation.</p>
                    </>
                },
                {
                    headline:"A Sojourn Through North India",
                    linktext:"Sojourn Through India",
                    key:"india-sojourn",
                    content:<>
                    <p>
                        Nestled in the shadow of the Himalayan Mountains, hidden from history and far from the hustle and bustle of a typical trip to India, 
                        are the quaint and meditative villages of the Kumaon region. You've traveled far for an escape from the stress of your daily life, to meditate, to 
                        experience a completely different culture and learn from its people. For a short while, nothing else matters but this moment. And that is the 
                        essence of the Buddhist philosophy that permeates this Tibetan journey. 
                    </p>
                    <Carousel nextIcon={<FontAwesomeIcon icon={faChevronDoubleRight} size="2x" /> } 
                            prevIcon={<FontAwesomeIcon icon={faChevronDoubleLeft} size="2x" /> } 
                            onSelect={(selectedIndex,e)=>{handleCarouselSelect('india',1,selectedIndex)}} activeIndex={indiaCarousel1Index} interval={null}>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_india_places_1} alt="Terrace outside 360 Leti" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_india_places_2} alt="360 Leti Cottage House" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_india_places_3} alt="Interior of 360 Leti Cottage House" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_india_places_4} alt="Shakti Deora House Exterior" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_india_places_6} alt="Shakti Deora House Exterior" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_india_places_5} alt="Shakti Nimoo House Exterior" />
                        </Carousel.Item>
                    </Carousel>

                    <p>
                        Dotted throughout the Northern region of India are charming villages
                        where you are an honored guest. Fully renovated, stylishly simple but well-adorned guest homes are waiting for 
                        you, complete with a private chef. Experience the deep sense of calm that imbues the region as you sip a fine wine, watching 
                        the sunset light up the tallest mountains in the world. 
                    </p>

                    <Carousel nextIcon={<FontAwesomeIcon icon={faChevronDoubleRight} size="2x" /> } 
                            prevIcon={<FontAwesomeIcon icon={faChevronDoubleLeft} size="2x" /> } 
                            onSelect={(selectedIndex,e)=>{handleCarouselSelect('india',2,selectedIndex)}} activeIndex={indiaCarousel2Index} interval={null}>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_india_journey_1} alt="Sikkim monastery" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_india_journey_2} alt="Hiking Near 360 Leti" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_india_journey_3} alt="Thiksey Monastery Monks" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_india_journey_4} alt="Rafting a River in India" />
                        </Carousel.Item>
                    </Carousel>

                    <p>
                        This vacation is customized for you. Throughout the journey, you may be driven, raft, or even trek by foot to the next location&mdash;with 
                        the experience tuned for your health and desires that day. Visit ancient monasteries that have stood for a thousand years, 
                        experience the deeply fulfilling ritual of morning Buddhist prayers, or arrange a private yoga or meditation session. Spend 
                        an afternoon sauntering through a rhododendron forest after a delightful, lazy picnic. 
                    </p>

                    <Carousel nextIcon={<FontAwesomeIcon icon={faChevronDoubleRight} size="2x" /> } 
                            prevIcon={<FontAwesomeIcon icon={faChevronDoubleLeft} size="2x" /> } 
                            onSelect={(selectedIndex,e)=>{handleCarouselSelect('india',3,selectedIndex)}} activeIndex={indiaCarousel3Index} interval={null}>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_india_people_1} alt="Street performers" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_india_people_2} alt="Shakti Kumaon Village Walks" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_india_people_3} alt="Local life in Kumaon" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_india_people_4} alt="Spring in Ladakh" />
                        </Carousel.Item>
                    </Carousel>

                    <p>Throughout your journey, the local people will share their way of life with you. The natural beauty abounds, as you hike or raft 
                        along rivers passing by local, sustainable farming communities. Learn to cook a traditional Indian meal from your private chef. 
                        Visit a <OutboundLink href="https://www.yogitimes.com/article/panchachuli-women-weavers-himalayas-india-nepal" target="_blank">women's weaving cooperative</OutboundLink>, 
                        a flourishing social enterprise that provides a sustainable income for local women. 
                    </p>

                    <p>
                        Experiencing a calmer and simpler way of life, you might not want to leave. But as you return home, take with you
                        the insights of a world apart from your norm. The experience travelling through Northern India will stay with you 
                        for the rest of your life. 
                    </p>
                    <ConsultationRequestTrigger color="red" size="sm" 
                        text={<>Let's Plan Your India Experience</>} 
                        onClick={()=>{startConsultationRequest('sample_india-sojourn')}} />
                    <p class="legalease">This description is based on the Shakti experiences, photographs provided by Shakti. The details of each Shakti journey are customized to your desires and needs. Outward Travel will help you plan your journey to this hidden land.</p>

                    </>
                },
                {
                    headline:<>Visit Ancient Sites of Faith <span className="hidden-xs hidden-sm hidden-md">& Spirituality</span></>,
                    linktext:"Ancient Christian Sites",
                    key:"ancient-sites-of-faith",
                    content:<>
                    <blockquote>"History cannot give us a program for the future, but it can give us a fuller understanding of ourselves, and of our common humanity, so that we can better face the future." <span className="attribution">- Robert Penn Warren</span></blockquote>
                    <p>The world’s oldest faiths are rich in history, with the stories of those who came before written in scripture, and the monuments they built still standing today. To better understand your faith—or other faiths—consider a voyage to the ancient lands to see the same places where history once tread. </p>
                    <Carousel nextIcon={<FontAwesomeIcon icon={faChevronDoubleRight} size="2x" /> } 
                            prevIcon={<FontAwesomeIcon icon={faChevronDoubleLeft} size="2x" /> } 
                            onSelect={(selectedIndex,e)=>{handleCarouselSelect('christian',1,selectedIndex)}} activeIndex={christianCarousel1Index} interval={null}>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_christian_paul_1} alt="Meteora monastery" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_christian_paul_2} alt="Agora of Ephesus" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_christian_paul_3} alt="Temples at Delphi" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_christian_paul_4} alt="Acropolis" />
                        </Carousel.Item>
                    </Carousel>
                    <h5>In the Footsteps of Paul the Apostle</h5>
                    <p>Paul the Apostle was an early leader of the Christian church, and he journeyed far and wide across the known world of the Mediterranean. Your tour will follow in his footsteps as you sojourn across beautiful Greece: the epicenter of thought and society. Begin with a three-night cruise along the islands of Mykonos, Patmos, and Santorini. You’ll stand in the ruins where Paul himself stood—where his letters in the New Testament were sent—like Ephesus, Thessaloniki, Crete, the Agora in Corinth, and the Cave of the Apocalypse where John the Evangelist wrote the Book of Revelation. See the astounding architecture of the Meteora monasteries and learn about the monks still serving there today. And all along the way, you’ll sample the finest modern cuisine Greece has to offer. </p>
                    <Carousel nextIcon={<FontAwesomeIcon icon={faChevronDoubleRight} size="2x" /> } 
                            prevIcon={<FontAwesomeIcon icon={faChevronDoubleLeft} size="2x" /> } 
                            onSelect={(selectedIndex,e)=>{handleCarouselSelect('christian',2,selectedIndex)}} activeIndex={christianCarousel2Index} interval={null}>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_christian_shrines_1} alt="basilica of san francesco d'assisi, by Cezar Suceveanu" title="Photo Credit: Cezar Suceveanu via Wikimedia" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_christian_shrines_2} alt="Abbey of Montecassino" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_christian_shrines_3} alt="The Basilica of St. Therese of Lisieux" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_christian_shrines_4} alt="Avignon Bridge with Popes Palace and Rhone river" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={itinerary_christian_shrines_5} alt="Kylemore Abbey" />
                        </Carousel.Item>
                    </Carousel>
                    <h5>See the Shrines of Italy, France, or Ireland</h5>
                    <p>Old Christianity spread throughout Europe, and on this continent rich with history you’ll find many shrines and important sites still standing today. In Italy, step underground to the catacombs of Rome where early Christians hid and buried their dead; then travel to the cathedrals throughout the country and celebrate Mass in the oldest churches of the faith. France is home to towering Gothic cathedrals where the saints first experienced their divine revelations; and of course, throughout the French countryside you’ll taste the finest cuisine and a nature-driven peace that drew so many others here over the last two millennia. Ireland, too, is rich with Catholic and Gaelic tradition; touring through the Emerald Isle will show you the churches, monasteries, and artifacts from the land and life of Saint Patrick. </p>
                    <p>Throughout these historically rich tours you’ll be guided by experts of history and faith, with hotels, most meals, and airfare from major US cities all included. Return home with a deeper understanding of the places and challenges faced by the early Christian church, and let that reinvigorate your own faith in today’s challenging world. </p>
                    <ConsultationRequestTrigger color="red" size="sm" 
                        text={<><span className="hidden-xs hidden-sm">Start Planning Your</span>
                                 <span className="hidden-md hidden-lg hidden-xl hidden-xxl">Plan a</span> Historical Sojourn</>} 
                        onClick={()=>{startConsultationRequest('sample_ancient-sites-of-faith')}} />
                    <p class="legalease">These example itineraries are based on separate guided tours provided by one or more operators we work with. To customize your own journey, speak with Outward Travel and we’ll find you the latest offers available only via travel agents, and help you plan an enriching, faith-filled journey.</p>

                    </>
                },
            ]} />
        </Tier3Layout>
    </main>
  )
}


export default SpiritualRestoration

